<!-- eslint-disable @intlify/vue-i18n/no-unused-keys -->
<template>
  <el-card header="Änderungen">
    <p v-if="addedUser != null" style="margin: 0px; word-break: break-word">
      <i18n-t keypath="aliasActionPreview.userChange.accountCreate">
        <template #email>
          <b>{{ addedUser.email }}</b>
        </template>
      </i18n-t>
    </p>

    <p v-if="deletedUser != null" style="word-break: break-word">
      <i18n-t keypath="aliasActionPreview.userChange.accountDelete">
        <template #email>
          <b>{{ deletedUser.email }}</b>
        </template>
      </i18n-t>
    </p>

    <div v-if="userAliases.length > 0">
      <p style="word-break: break-word">
        <i18n-t
          :keypath="
            'aliasActionPreview.userChange.' + props.action + 'HeaderText'
          ">
          <template #email>
            <b>{{ user.email }}</b>
          </template>
          <template #alias>
            <b>{{ props.alias }}</b>
          </template>
        </i18n-t>
      </p>
    </div>
    <div v-else-if="userAliases.length === 0">
      <p style="word-break: break-word">
        <i18n-t keypath="aliasActionPreview.noUserChange.headerText">
          <template #email>
            <b>{{ user.email }}</b>
          </template>
        </i18n-t>
      </p>
    </div>

    <div v-if="users != null && users.length > 0">
      <p style="word-break: break-word">
        {{ i18n.t('aliasActionPreview.userTable.headerText') }}
      </p>
      <el-table :data="users">
        <el-table-column
          prop="email"
          :label="i18n.t('aliasActionPreview.userTable.email')">
          <template #default="{ row }">
            <TextMultiline
              :text="row.email"
              :lines-to-show="1"
              :show-tool-tip="true" />
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          :label="i18n.t('aliasActionPreview.userTable.name')">
          <template #default="{ row }">
            <TextMultiline
              :text="row.name"
              :lines-to-show="1"
              :show-tool-tip="true" />
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div v-if="licences != null && licences.length > 0">
      <h4 style="word-break: break-word">
        {{
          i18n.t(
            'aliasActionPreview.licencesTable.' + props.action + 'HeaderText'
          )
        }}
      </h4>
      <el-table :data="licences">
        <el-table-column
          prop="software_id"
          :label="i18n.t('aliasActionPreview.licencesTable.software_name')">
          <template #default="{ row }">
            <TextMultiline
              :text="
                getSoftware(row.software_id)?.display_name ||
                getSoftware(row.software_id)?.software_name
              "
              :lines-to-show="1"
              :show-tool-tip="true" />
          </template>
        </el-table-column>

        <el-table-column
          prop="email"
          :label="i18n.t('aliasActionPreview.licencesTable.email')">
          <template #default="{ row }">
            <TextMultiline
              :text="row.email"
              :lines-to-show="1"
              :show-tool-tip="true" />
          </template>
        </el-table-column>
      </el-table>
    </div>

    <h4
      v-if="
        user == null &&
        addedUser == null &&
        deletedUser == null &&
        (users == null ||
          (users != null &&
            users.length == 0 &&
            (licences == null || (licences != null && licences.length == 0))))
      ">
      {{ i18n.t('aliasActionPreview.noChanges') }}
    </h4>
  </el-card>
</template>

<!-- eslint-disable vue/require-default-prop -->
<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import { LicenceOut, SoftwareOut } from '@/client'
  import { useSoftwareStore } from '@/stores/softwareStore'
  import { User } from '@/types/user'

  const softwareStore = useSoftwareStore()

  const allApps = ref<SoftwareOut[]>([])

  const props = defineProps({
    // Created alias
    alias: {
      type: String,
      required: true,
    },
    // Can the "created" / "deleted"
    action: {
      type: String,
      required: false,
      default: 'created',
    },
    // User that was
    user: {
      type: Object as PropType<User>,
      default: () => null,
    },
    // The addedUser if the backend created a new user from an alias
    addedUser: {
      type: Object as PropType<User>,
      default: () => null,
    },
    // The deletedUser if the backend merged a user into another user
    deletedUser: {
      type: Object as PropType<User>,
      default: () => null,
    },
    // The users where the alias was found to, and where it gonna be removed
    users: {
      type: Array as PropType<User[]>,
      default: () => [],
    },
    // The licences where the alias effects the account_id of the licence
    licences: {
      type: Array as PropType<LicenceOut[]>,
      default: () => [],
    },
  })

  const i18n = useI18n()

  function getSoftware(id: string) {
    return allApps.value.find((app) => app._id === id)
  }

  const userAliases = computed(() => {
    if (props.user == null) {
      return []
    }
    return Object.entries(props.user.aliases || {}).map(([key, value]) => {
      return {
        name: key,
        description: value.description,
      }
    })
  })
  onMounted(async () => {
    allApps.value = await softwareStore.getSoftware()
  })
</script>
