/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BackgroundJobSoftwareUserLicenseAddExecutionData } from './BackgroundJobSoftwareUserLicenseAddExecutionData';
import type { BackgroundJobStatus } from './BackgroundJobStatus';
export type BackgroundJobExecutionBase_Literal_software_user_license_add__BackgroundJobSoftwareUserLicenseAddExecutionData_ = {
    type: BackgroundJobExecutionBase_Literal_software_user_license_add__BackgroundJobSoftwareUserLicenseAddExecutionData_.type;
    execution_id: string;
    executed_at: string;
    data?: (BackgroundJobSoftwareUserLicenseAddExecutionData | null);
    status?: BackgroundJobStatus;
    error_event_id?: (string | null);
    background_job_ids_enqueued?: Array<string>;
};
export namespace BackgroundJobExecutionBase_Literal_software_user_license_add__BackgroundJobSoftwareUserLicenseAddExecutionData_ {
    export enum type {
        SOFTWARE_USER_LICENSE_ADD = 'software_user_license_add',
    }
}

