<!-- SM Datepicker -->
<template>
  <div v-if="props.isDropdown" ref="dropdown" class="sm-datepicker">
    <SmDropdown
      ref="dropdownRef"
      :close-on-click-inside="false"
      :class="{ 'cursor-not-allowed': props.disabled }"
      dropdown-class="min-w-64 !max-w-80"
      max-height="500px"
      type="panel">
      <template #trigger>
        <div
          class="dropdown-select flex border border-gray-300 dark:border-gray-600">
          <slot name="trigger">
            <v-icon name="hi-calendar" scale="1.1" class="mr-4" />
            <template v-if="date"> {{ displayDate(date) }}</template>
            <template v-else>
              <span class="text-contrast-muted">{{
                props.placeholder || i18n.t('pleaseSelectDate')
              }}</span>
            </template>
          </slot>

          <!-- dropdown indicator -->
          <v-icon
            name="md-expandmore-round"
            scale="1.5"
            class="dropdown-indicator ml-auto cursor-pointer" />
        </div>
      </template>

      <!-- Add calender -->
      <Calendar
        v-if="!props.isRange"
        v-model="calendarDate"
        :weekday-format="'short'"
        :locale="locale"
        :min-value="today(getLocalTimeZone())"
        :week-starts-on="1" />

      <RangeCalendar
        v-if="props.isRange"
        v-model="calendarDateRange"
        :weekday-format="'short'"
        :locale="locale"
        :week-starts-on="1" />
    </SmDropdown>
  </div>

  <div v-else-if="!props.isDropdown">
    <Calendar
      v-if="!props.isRange"
      v-model="calendarDate"
      :weekday-format="'short'"
      :locale="locale"
      :min-value="today(getLocalTimeZone())"
      :week-starts-on="1" />

    <RangeCalendar
      v-if="props.isRange"
      v-model="calendarDateRange"
      :weekday-format="'short'"
      :locale="locale"
      :week-starts-on="1" />
  </div>
</template>

<script setup lang="ts" generic="T">
  import {
    DateValue,
    getLocalTimeZone,
    parseDate,
    today,
  } from '@internationalized/date'
  import SmDropdown from './SmDropdown.vue'
  import { displayDate } from '@/common/util/timeUtils'
  import dayjs from 'dayjs'
  import { usePreferenceStore } from '@/stores/preferenceStore'
  import { DateRange } from 'radix-vue'
  import { useI18n } from 'vue-i18n'

  const i18n = useI18n()

  const emit = defineEmits(['update:date', 'update:daterange'])
  const date = defineModel('date', { type: Object as PropType<Date> })
  const daterange = defineModel('daterange', {
    type: Object as PropType<Date[]>,
  })
  const preferenceStore = usePreferenceStore()

  const props = defineProps({
    isDropdown: {
      type: Boolean,
      default: true,
    },
    isRange: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  })

  const locale = computed(() => {
    switch (preferenceStore.preferences?.language) {
      case 'de':
        return 'de-DE'
      case 'en':
        return 'en-US'
      default:
        return 'de-DE'
    }
  })

  const calendarDateRange = computed<DateRange | undefined>({
    get() {
      if (daterange.value) {
        return {
          start: parseDate(dayjs(daterange.value[0]).format('YYYY-MM-DD')),
          end: parseDate(dayjs(daterange.value[1]).format('YYYY-MM-DD')),
        }
      } else {
        return undefined
      }
    },
    set(value) {
      if (value && value.start && value.end) {
        const start = value.start.toDate
          ? value.start.toDate(getLocalTimeZone())
          : undefined
        const end = value.end.toDate
          ? value.end.toDate(getLocalTimeZone())
          : undefined

        emit('update:daterange', [start, end])
      }
    },
  })

  // Computed property to convert native Date to DateValue
  const calendarDate = computed<DateValue | undefined>({
    get() {
      return date.value
        ? parseDate(dayjs(date.value).format('YYYY-MM-DD'))
        : undefined
    },
    set(value) {
      // Convert CalendarDate back to native Date
      if (value) {
        const nativeDate = value.toDate(getLocalTimeZone())
        emit('update:date', nativeDate)
      } else {
        emit('update:date', undefined)
      }
    },
  })
</script>

<style lang="scss">
  .sm-datepicker {
    .trigger {
      .dropdown-indicator {
        transition: all 0.2s ease;
        transform: rotate(0deg);
      }
      &.active {
        .dropdown-indicator {
          transform: rotate(180deg);
        }
      }
    }
  }
  input {
    background: none;
  }

  .dropdown-select {
    display: flex;
    align-items: center;

    padding: 0.5rem 1rem;

    border-radius: var(--border-radius-base);
    font-size: 1rem;
  }

  .dropdown-item {
    border-radius: var(--border-radius-base);
  }

  .is-error .dropdown-select {
    border-color: var(--sm-red);
  }
</style>
