/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BackgroundJobAccountGroupAccountsAdded } from '../models/BackgroundJobAccountGroupAccountsAdded';
import type { BackgroundJobAccountGroupAccountsRemoved } from '../models/BackgroundJobAccountGroupAccountsRemoved';
import type { BackgroundJobEnqueuePostIn } from '../models/BackgroundJobEnqueuePostIn';
import type { BackgroundJobSoftwareUserAdd } from '../models/BackgroundJobSoftwareUserAdd';
import type { BackgroundJobSoftwareUserAddPostIn } from '../models/BackgroundJobSoftwareUserAddPostIn';
import type { BackgroundJobSoftwareUserEnable } from '../models/BackgroundJobSoftwareUserEnable';
import type { BackgroundJobSoftwareUserEnablePostIn } from '../models/BackgroundJobSoftwareUserEnablePostIn';
import type { BackgroundJobSoftwareUserLicenseAdd } from '../models/BackgroundJobSoftwareUserLicenseAdd';
import type { BackgroundJobSoftwareUserLicenseAddPostIn } from '../models/BackgroundJobSoftwareUserLicenseAddPostIn';
import type { BackgroundJobSoftwareUserLicenseRemove } from '../models/BackgroundJobSoftwareUserLicenseRemove';
import type { BackgroundJobSoftwareUserLicenseRemovePostIn } from '../models/BackgroundJobSoftwareUserLicenseRemovePostIn';
import type { BackgroundJobSoftwareUserRemove } from '../models/BackgroundJobSoftwareUserRemove';
import type { BackgroundJobSoftwareUserRemovePostIn } from '../models/BackgroundJobSoftwareUserRemovePostIn';
import type { BackgroundJobStatus } from '../models/BackgroundJobStatus';
import type { BackgroundJobType } from '../models/BackgroundJobType';
import type { PaginationSortOrder } from '../models/PaginationSortOrder';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class BackgroundJobsService {
    /**
     * Get Background Jobs
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getBackgroundJobsApiV1BackgroundJobsGet({
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        typeExists,
        typeEq,
        typeGt,
        typeGte,
        typeIn,
        typeLt,
        typeLte,
        typeNe,
        typeNin,
        type,
        status,
        lastExecutedAtExists,
        lastExecutedAtEq,
        lastExecutedAtGt,
        lastExecutedAtGte,
        lastExecutedAtIn,
        lastExecutedAtLt,
        lastExecutedAtLte,
        lastExecutedAtNe,
        lastExecutedAtNin,
        lastExecutedAt,
        nextExecutionAtExists,
        nextExecutionAtEq,
        nextExecutionAtGt,
        nextExecutionAtGte,
        nextExecutionAtIn,
        nextExecutionAtLt,
        nextExecutionAtLte,
        nextExecutionAtNe,
        nextExecutionAtNin,
        nextExecutionAt,
        completedExists,
        completedEq,
        completedGt,
        completedGte,
        completedIn,
        completedLt,
        completedLte,
        completedNe,
        completedNin,
        completed,
        limit,
        offset,
        sortBy,
        sortOrder,
        sort,
        xTransactionId,
    }: {
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        typeExists?: (boolean | null),
        typeEq?: (BackgroundJobType | null),
        typeGt?: (BackgroundJobType | null),
        typeGte?: (BackgroundJobType | null),
        typeIn?: (Array<BackgroundJobType> | null),
        typeLt?: (BackgroundJobType | null),
        typeLte?: (BackgroundJobType | null),
        typeNe?: (BackgroundJobType | null),
        typeNin?: (Array<BackgroundJobType> | null),
        type?: (BackgroundJobType | null),
        status?: BackgroundJobStatus,
        lastExecutedAtExists?: (boolean | null),
        lastExecutedAtEq?: (string | null),
        lastExecutedAtGt?: (string | null),
        lastExecutedAtGte?: (string | null),
        lastExecutedAtIn?: (Array<string> | null),
        lastExecutedAtLt?: (string | null),
        lastExecutedAtLte?: (string | null),
        lastExecutedAtNe?: (string | null),
        lastExecutedAtNin?: (Array<string> | null),
        lastExecutedAt?: (string | null),
        nextExecutionAtExists?: (boolean | null),
        nextExecutionAtEq?: (string | null),
        nextExecutionAtGt?: (string | null),
        nextExecutionAtGte?: (string | null),
        nextExecutionAtIn?: (Array<string> | null),
        nextExecutionAtLt?: (string | null),
        nextExecutionAtLte?: (string | null),
        nextExecutionAtNe?: (string | null),
        nextExecutionAtNin?: (Array<string> | null),
        nextExecutionAt?: (string | null),
        completedExists?: (boolean | null),
        completedEq?: (boolean | null),
        completedGt?: (boolean | null),
        completedGte?: (boolean | null),
        completedIn?: (Array<boolean> | null),
        completedLt?: (boolean | null),
        completedLte?: (boolean | null),
        completedNe?: (boolean | null),
        completedNin?: (Array<boolean> | null),
        completed?: (boolean | null),
        limit?: (number | null),
        offset?: (number | null),
        /**
         * Field name to be sorted by. Ignored if 'sort' is specified
         */
        sortBy?: (string | null),
        /**
         * Sort order to be sorted by. Ignored if 'sort' is specified
         */
        sortOrder?: (PaginationSortOrder | null),
        /**
         * Sort field in the form `<field_name>:<sort_by>`. Example: `sort=email:asc`. If at least one is set, sort_by and sort_order are ignored
         */
        sort?: (Array<string> | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<(BackgroundJobAccountGroupAccountsAdded | BackgroundJobAccountGroupAccountsRemoved | BackgroundJobSoftwareUserAdd | BackgroundJobSoftwareUserEnable | BackgroundJobSoftwareUserLicenseAdd | BackgroundJobSoftwareUserLicenseRemove | BackgroundJobSoftwareUserRemove)>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/background-jobs',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'type.$exists': typeExists,
                'type.$eq': typeEq,
                'type.$gt': typeGt,
                'type.$gte': typeGte,
                'type.$in': typeIn,
                'type.$lt': typeLt,
                'type.$lte': typeLte,
                'type.$ne': typeNe,
                'type.$nin': typeNin,
                'type': type,
                'status': status,
                'last_executed_at.$exists': lastExecutedAtExists,
                'last_executed_at.$eq': lastExecutedAtEq,
                'last_executed_at.$gt': lastExecutedAtGt,
                'last_executed_at.$gte': lastExecutedAtGte,
                'last_executed_at.$in': lastExecutedAtIn,
                'last_executed_at.$lt': lastExecutedAtLt,
                'last_executed_at.$lte': lastExecutedAtLte,
                'last_executed_at.$ne': lastExecutedAtNe,
                'last_executed_at.$nin': lastExecutedAtNin,
                'last_executed_at': lastExecutedAt,
                'next_execution_at.$exists': nextExecutionAtExists,
                'next_execution_at.$eq': nextExecutionAtEq,
                'next_execution_at.$gt': nextExecutionAtGt,
                'next_execution_at.$gte': nextExecutionAtGte,
                'next_execution_at.$in': nextExecutionAtIn,
                'next_execution_at.$lt': nextExecutionAtLt,
                'next_execution_at.$lte': nextExecutionAtLte,
                'next_execution_at.$ne': nextExecutionAtNe,
                'next_execution_at.$nin': nextExecutionAtNin,
                'next_execution_at': nextExecutionAt,
                'completed.$exists': completedExists,
                'completed.$eq': completedEq,
                'completed.$gt': completedGt,
                'completed.$gte': completedGte,
                'completed.$in': completedIn,
                'completed.$lt': completedLt,
                'completed.$lte': completedLte,
                'completed.$ne': completedNe,
                'completed.$nin': completedNin,
                'completed': completed,
                'limit': limit,
                'offset': offset,
                'sort_by': sortBy,
                'sort_order': sortOrder,
                'sort': sort,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Background Job
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createBackgroundJobApiV1BackgroundJobsPost({
        requestBody,
        xTransactionId,
    }: {
        requestBody: (BackgroundJobSoftwareUserAddPostIn | BackgroundJobSoftwareUserEnablePostIn | BackgroundJobSoftwareUserLicenseAddPostIn | BackgroundJobSoftwareUserLicenseRemovePostIn | BackgroundJobSoftwareUserRemovePostIn),
        xTransactionId?: (string | null),
    }): CancelablePromise<(BackgroundJobAccountGroupAccountsAdded | BackgroundJobAccountGroupAccountsRemoved | BackgroundJobSoftwareUserAdd | BackgroundJobSoftwareUserEnable | BackgroundJobSoftwareUserLicenseAdd | BackgroundJobSoftwareUserLicenseRemove | BackgroundJobSoftwareUserRemove)> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/background-jobs',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Count Background Jobs
     * @returns number Successful Response
     * @throws ApiError
     */
    public static countBackgroundJobsApiV1BackgroundJobsCountGet({
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        typeExists,
        typeEq,
        typeGt,
        typeGte,
        typeIn,
        typeLt,
        typeLte,
        typeNe,
        typeNin,
        type,
        status,
        lastExecutedAtExists,
        lastExecutedAtEq,
        lastExecutedAtGt,
        lastExecutedAtGte,
        lastExecutedAtIn,
        lastExecutedAtLt,
        lastExecutedAtLte,
        lastExecutedAtNe,
        lastExecutedAtNin,
        lastExecutedAt,
        nextExecutionAtExists,
        nextExecutionAtEq,
        nextExecutionAtGt,
        nextExecutionAtGte,
        nextExecutionAtIn,
        nextExecutionAtLt,
        nextExecutionAtLte,
        nextExecutionAtNe,
        nextExecutionAtNin,
        nextExecutionAt,
        completedExists,
        completedEq,
        completedGt,
        completedGte,
        completedIn,
        completedLt,
        completedLte,
        completedNe,
        completedNin,
        completed,
        xTransactionId,
    }: {
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        typeExists?: (boolean | null),
        typeEq?: (BackgroundJobType | null),
        typeGt?: (BackgroundJobType | null),
        typeGte?: (BackgroundJobType | null),
        typeIn?: (Array<BackgroundJobType> | null),
        typeLt?: (BackgroundJobType | null),
        typeLte?: (BackgroundJobType | null),
        typeNe?: (BackgroundJobType | null),
        typeNin?: (Array<BackgroundJobType> | null),
        type?: (BackgroundJobType | null),
        status?: BackgroundJobStatus,
        lastExecutedAtExists?: (boolean | null),
        lastExecutedAtEq?: (string | null),
        lastExecutedAtGt?: (string | null),
        lastExecutedAtGte?: (string | null),
        lastExecutedAtIn?: (Array<string> | null),
        lastExecutedAtLt?: (string | null),
        lastExecutedAtLte?: (string | null),
        lastExecutedAtNe?: (string | null),
        lastExecutedAtNin?: (Array<string> | null),
        lastExecutedAt?: (string | null),
        nextExecutionAtExists?: (boolean | null),
        nextExecutionAtEq?: (string | null),
        nextExecutionAtGt?: (string | null),
        nextExecutionAtGte?: (string | null),
        nextExecutionAtIn?: (Array<string> | null),
        nextExecutionAtLt?: (string | null),
        nextExecutionAtLte?: (string | null),
        nextExecutionAtNe?: (string | null),
        nextExecutionAtNin?: (Array<string> | null),
        nextExecutionAt?: (string | null),
        completedExists?: (boolean | null),
        completedEq?: (boolean | null),
        completedGt?: (boolean | null),
        completedGte?: (boolean | null),
        completedIn?: (Array<boolean> | null),
        completedLt?: (boolean | null),
        completedLte?: (boolean | null),
        completedNe?: (boolean | null),
        completedNin?: (Array<boolean> | null),
        completed?: (boolean | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/background-jobs/count',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'type.$exists': typeExists,
                'type.$eq': typeEq,
                'type.$gt': typeGt,
                'type.$gte': typeGte,
                'type.$in': typeIn,
                'type.$lt': typeLt,
                'type.$lte': typeLte,
                'type.$ne': typeNe,
                'type.$nin': typeNin,
                'type': type,
                'status': status,
                'last_executed_at.$exists': lastExecutedAtExists,
                'last_executed_at.$eq': lastExecutedAtEq,
                'last_executed_at.$gt': lastExecutedAtGt,
                'last_executed_at.$gte': lastExecutedAtGte,
                'last_executed_at.$in': lastExecutedAtIn,
                'last_executed_at.$lt': lastExecutedAtLt,
                'last_executed_at.$lte': lastExecutedAtLte,
                'last_executed_at.$ne': lastExecutedAtNe,
                'last_executed_at.$nin': lastExecutedAtNin,
                'last_executed_at': lastExecutedAt,
                'next_execution_at.$exists': nextExecutionAtExists,
                'next_execution_at.$eq': nextExecutionAtEq,
                'next_execution_at.$gt': nextExecutionAtGt,
                'next_execution_at.$gte': nextExecutionAtGte,
                'next_execution_at.$in': nextExecutionAtIn,
                'next_execution_at.$lt': nextExecutionAtLt,
                'next_execution_at.$lte': nextExecutionAtLte,
                'next_execution_at.$ne': nextExecutionAtNe,
                'next_execution_at.$nin': nextExecutionAtNin,
                'next_execution_at': nextExecutionAt,
                'completed.$exists': completedExists,
                'completed.$eq': completedEq,
                'completed.$gt': completedGt,
                'completed.$gte': completedGte,
                'completed.$in': completedIn,
                'completed.$lt': completedLt,
                'completed.$lte': completedLte,
                'completed.$ne': completedNe,
                'completed.$nin': completedNin,
                'completed': completed,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Background Job
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getBackgroundJobApiV1BackgroundJobsBackgroundJobIdGet({
        backgroundJobId,
        includeDeleted = false,
        xTransactionId,
    }: {
        backgroundJobId: string,
        includeDeleted?: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<(BackgroundJobAccountGroupAccountsAdded | BackgroundJobAccountGroupAccountsRemoved | BackgroundJobSoftwareUserAdd | BackgroundJobSoftwareUserEnable | BackgroundJobSoftwareUserLicenseAdd | BackgroundJobSoftwareUserLicenseRemove | BackgroundJobSoftwareUserRemove)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/background-jobs/{background_job_id}',
            path: {
                'background_job_id': backgroundJobId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'include_deleted': includeDeleted,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Enqueue Background Job By Id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static enqueueBackgroundJobByIdApiV1BackgroundJobsBackgroundJobIdEnqueuePost({
        backgroundJobId,
        includeDeleted = false,
        xTransactionId,
        requestBody,
    }: {
        backgroundJobId: string,
        includeDeleted?: boolean,
        xTransactionId?: (string | null),
        requestBody?: BackgroundJobEnqueuePostIn,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/background-jobs/{background_job_id}/enqueue',
            path: {
                'background_job_id': backgroundJobId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'include_deleted': includeDeleted,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Pause Background Job
     * @returns any Successful Response
     * @throws ApiError
     */
    public static pauseBackgroundJobApiV1BackgroundJobsBackgroundJobIdPausePost({
        backgroundJobId,
        includeDeleted = false,
        xTransactionId,
    }: {
        backgroundJobId: string,
        includeDeleted?: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/background-jobs/{background_job_id}/pause',
            path: {
                'background_job_id': backgroundJobId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'include_deleted': includeDeleted,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Abort Background Job
     * @returns any Successful Response
     * @throws ApiError
     */
    public static abortBackgroundJobApiV1BackgroundJobsBackgroundJobIdAbortPost({
        backgroundJobId,
        includeDeleted = false,
        xTransactionId,
    }: {
        backgroundJobId: string,
        includeDeleted?: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/background-jobs/{background_job_id}/abort',
            path: {
                'background_job_id': backgroundJobId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'include_deleted': includeDeleted,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
