<template>
  <Transition name="fade">
    <div v-show="logoutTime - timeUntilLogout > 1 * 1000" class="logout-timer">
      {{ i18n.t('logoutInX', { time: timeUntilLogoutFormatted }) }}
    </div>
  </Transition>
</template>

<script setup lang="ts">
  import { useIdle, useTimestamp } from '@vueuse/core'
  import dayjs from 'dayjs'

  import { SessionStatus, useSessionStore } from '@/stores/sessionStore'
  import { useI18n } from 'vue-i18n'

  const i18n = useI18n()
  const sessionStore = useSessionStore()

  const props = defineProps({
    logoutTime: {
      type: Number,
      required: true,
    },
  })

  const isLoggedOut = ref(sessionStore.status == SessionStatus.LOGGED_OUT) // used to prevent multiple logouts in a row (because sessionStore.status updates too slow)
  const { lastActive } = useIdle(props.logoutTime)

  const now = useTimestamp()

  const idledFor = computed(() => Math.floor(now.value - lastActive.value))
  const timeUntilLogout = computed(() => {
    return Math.floor(props.logoutTime - idledFor.value + 1000) // the + 1000 provides an extra second to make the countdown look more clean
  })

  const timeUntilLogoutFormatted = computed(() => {
    return dayjs.duration(timeUntilLogout.value).format('mm:ss')
  })

  watch(
    () => sessionStore.status,
    (status) => {
      if (status == SessionStatus.LOGGED_OUT) isLoggedOut.value = true
      else isLoggedOut.value = false
    }
  )

  watch(timeUntilLogout, (time) => {
    if (time <= 0 && !isLoggedOut.value) {
      sessionStore.logout()
      isLoggedOut.value = true
    }
  })
</script>
