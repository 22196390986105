<!-- eslint-disable vue/no-v-html -->
<template>
  <div :class="['wrapper', 'visible']">
    <div class="software-selection">
      <div style="padding-right: 0.5rem">
        <SmInput
          v-model="search"
          class="search-input"
          :label="i18n.t('search')"
          size="small"
          outline />
      </div>
      <div>
        <div v-auto-animate class="application-list">
          <!-- Application Card -->
          <div
            v-for="item in softwareFilter"
            :key="item._id"
            :class="{
              active: isCardActive(item._id),
              'software-selection-card': true,
            }"
            class="software-selection-card border border-gray-300 dark:border-gray-700"
            @click="() => handleClick(item)">
            <!-- Icon -->
            <ApplicationIcon :software-name="item.software_name" size="40px" />

            <!-- Information -->
            <div class="software-information">
              <span
                class="software-text"
                v-html="highlightSearchSubstring(item.display_name)" />

              <span v-if="getSelectedLicense(item._id).join(', ').length > 36">
                <SmTooltip>
                  <template #content>
                    {{ getSelectedLicense(item._id).join(', ') }}
                  </template>
                  <span>
                    {{
                      i18n.t(
                        'licensesCount',
                        getSelectedLicense(item._id).length
                      )
                    }}
                    {{ i18n.t('selected') }}
                  </span>
                  <v-icon
                    name="md-help-round"
                    scale="0.8"
                    style="margin-left: 8px" />
                </SmTooltip>
              </span>
              <span
                v-else-if="getSelectedLicense(item._id).length === 0"
                class="text-muted">
                {{ i18n.t('licensesCount', 0) }} {{ i18n.t('selected') }}
              </span>
              <span v-else>
                {{ getSelectedLicense(item._id).join(', ') }}
              </span>
            </div>

            <!-- Switch -->
            <div class="switch-wrapper">
              <SmTooltip>
                <template #content>
                  <span>{{
                    i18n.t('groupsCreateAccountTip', {
                      name: item.display_name,
                    })
                  }}</span>
                </template>
                <SmSwitch
                  v-model="activeSoftware[item._id]"
                  @select="handleSoftwareSelect(item._id)"
                  @unselect="handleSoftwareUnselect(item._id)" />
              </SmTooltip>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div :class="['license-selection', 'visible']">
      <h2>{{ currentlyEdited?.display_name }}</h2>

      <div>{{ currentlyEdited?.desc }}</div>

      <div style="height: 16px" />

      <el-tabs
        v-if="
          currentlyEdited &&
          Object.values(currentlyEdited.sub_accounts).length > 0 &&
          !currentlyEdited.sub_accounts['main']
        "
        v-model="selectedSubAccountId">
        <el-tab-pane
          v-for="sub_account in Object.values(currentlyEdited.sub_accounts)"
          :key="sub_account.id"
          :name="sub_account.id"
          :label="sub_account.name">
        </el-tab-pane>
      </el-tabs>
      <LicenseModelEditTable
        v-if="currentlyEdited"
        :key="currentlyEdited._id"
        ref="softwareLicenseEditTable"
        :software-licenses="currentlyEditedLicensesWithSubaccountFilter"
        :price-informations="allPriceInformations"
        :sub-account-id="selectedSubAccountId"
        :software="currentlyEdited"
        @selected-all="handleLicenseSelectedAll"
        @unselected-all="handleLicenseUnselectedAll"
        @selected="handleLicenseSelected"
        @unselected="handleLicenseUnselected"
        @close="currentlyEdited = null" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ComponentExposed } from 'vue-component-type-helpers'
  import { useI18n } from 'vue-i18n'
  import SmSwitch from '@/components/sm/SmSwitch.vue'

  import { PriceInformation, SoftwareLicense, SoftwareOut } from '@/client'

  const i18n = useI18n()
  const props = defineProps<{
    allApplications: SoftwareOut[]
    allSoftwareLicenses: SoftwareLicense[]
    allPriceInformations: PriceInformation[]
  }>()

  import LicenseModelEditTable from '@/components/LicenseModelEditTable.vue'
  import { ModelRef } from 'vue'

  const selectedSubAccountId = ref<string>('main')
  const softwareLicenseEditTable = ref<ComponentExposed<
    typeof LicenseModelEditTable
  > | null>(null)

  const currentlyEdited = ref<SoftwareOut | null>(null)

  type SoftwareLicenseID = string
  export interface SelectionState {
    [softwareId: string]: null | {
      [subAccountId: string]: Record<SoftwareLicenseID, SoftwareLicense | null>
    }
  }

  const selectionState: ModelRef<SelectionState> = defineModel('selection', {
    type: Object as PropType<SelectionState>,
    default: () => ({}),
  })

  onMounted(() => {
    currentlyEdited.value = softwareFilter.value[0]
  })

  const activeSoftware = ref<Record<string, boolean>>({})

  const currentlyEditedLicenses = computed(() => {
    return props.allSoftwareLicenses.filter(
      (l) => l.software_id === currentlyEdited.value?._id
    )
  })

  const currentlyEditedLicensesWithSubaccountFilter = computed(() => {
    return currentlyEditedLicenses.value.filter(
      (license) => license.sub_account_id === selectedSubAccountId.value
    )
  })

  // #region Search
  const search = ref<string>('')
  const softwareFilter = computed(() => {
    return props.allApplications
      .filter((item) => {
        const name = item.display_name || ''

        return name.toLowerCase().includes(search.value.toLowerCase())
      })
      .filter((item) => {
        // Hide SSO providers
        return !item.information.sso_provider
      })
      .sort((a, b) => {
        const nameA = a.display_name || ''
        const nameB = b.display_name || ''

        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
  })

  function highlightSearchSubstring(text: string) {
    if (!search.value) return text

    const regex = new RegExp(search.value, 'gi')
    return text.replace(regex, (match) => `<b>${match}</b>`)
  }
  // #endregion

  // #region License
  function applySelection() {
    if (!softwareLicenseEditTable.value) return
    if (!softwareLicenseEditTable.value.table) return // Should not happen
    const _selectedSoftware =
      selectionState.value[currentlyEdited.value?._id || '']

    // Select the entry in the table for each actual selected license
    if (_selectedSoftware == null) return
    Object.entries(_selectedSoftware[selectedSubAccountId.value] || {}).forEach(
      ([id, value]) => {
        if (value != null) softwareLicenseEditTable.value?.table?.selectById(id)
      }
    )
  }

  function handleLicenseSelectedAll(licenses: SoftwareLicense[]) {
    licenses.forEach((l) => handleLicenseSelected(l))
  }
  function handleLicenseUnselectedAll(licenses: SoftwareLicense[]) {
    licenses.forEach((l) => handleLicenseUnselected(l))
  }

  function handleLicenseSelected(license: SoftwareLicense) {
    if (!currentlyEdited.value) return

    const softwareId = currentlyEdited.value._id

    activeSoftware.value[softwareId] = true

    if (!selectionState.value[softwareId]) selectionState.value[softwareId] = {}

    if (!selectionState.value[softwareId]![selectedSubAccountId.value])
      selectionState.value[softwareId]![selectedSubAccountId.value] = {}
    selectionState.value[softwareId]![selectedSubAccountId.value][license._id] =
      license
  }

  function handleLicenseUnselected(license: SoftwareLicense) {
    if (!currentlyEdited.value) return

    const softwareId = currentlyEdited.value._id

    if (!selectionState.value[softwareId]) selectionState.value[softwareId] = {}

    if (!selectionState.value[softwareId]![selectedSubAccountId.value])
      selectionState.value[softwareId]![selectedSubAccountId.value] = {}

    selectionState.value[softwareId]![selectedSubAccountId.value][license._id] =
      null
  }

  watch(
    [() => currentlyEdited.value, () => selectedSubAccountId.value],
    ([newSoftware, newSubaccountId], [, oldSubaccountId]) => {
      if (!currentlyEdited.value) return

      // software Changes -> Select first sub_account of software by default
      if (newSubaccountId == oldSubaccountId) {
        if (Object.keys(currentlyEdited.value.sub_accounts).length > 0) {
          selectedSubAccountId.value = Object.keys(
            currentlyEdited.value.sub_accounts
          )[0]
        } else selectedSubAccountId.value = 'main'
      }

      // sub_account changes, de-select the table entries and re-select the right ones
      if (
        oldSubaccountId != newSubaccountId &&
        newSoftware &&
        oldSubaccountId
      ) {
        if (!selectionState.value[newSoftware._id]) {
          return
        }
        Object.keys(
          selectionState.value[newSoftware._id]![oldSubaccountId] || {}
        ).forEach((id) => {
          softwareLicenseEditTable?.value?.table?.deselectById(id)
        })
      }

      nextTick(() => {
        applySelection()
      })
    }
  )

  // #endregion

  // #region Software
  function handleSoftwareSelect(_id: string) {
    if (!selectionState.value[_id]) {
      selectionState.value[_id] = {}
    }
  }

  function handleSoftwareUnselect(_id: string) {
    selectionState.value[_id] = null
  }

  // #endregion

  // Utils
  function isCardActive(_id: string) {
    return currentlyEdited.value?._id === _id
  }

  function handleClick(item: SoftwareOut) {
    currentlyEdited.value = item
    applySelection()
  }

  function getSelectedLicense(softwareId: string) {
    const selectedLicense: string[] = []

    Object.keys(selectionState.value[softwareId] || {}).forEach(
      (subAccountId) => {
        if (!selectionState.value[softwareId]) return
        Object.values(selectionState.value[softwareId]![subAccountId]).forEach(
          (v) => {
            if (v != null) selectedLicense.push(v.name)
          }
        )
      }
    )
    return selectedLicense
  }

  watch(
    () => selectionState.value,
    () => {
      // Update activeSoftware
      activeSoftware.value = Object.entries(selectionState.value).reduce(
        (acc, curr) => {
          if (curr[1] != null) acc[curr[0]] = true
          return acc
        },
        {} as Record<string, boolean>
      )
    },
    { deep: true, immediate: true }
  )
</script>

<style scoped lang="scss">
  .license-selection {
    min-width: 0;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    padding: 0;
    margin: 0;

    transition: all 0.3s ease-in-out;

    max-height: 100%;
    overflow-y: auto;

    &.visible {
      border-left: 1px solid var(--sm-cta);
      padding-left: 1rem;
    }
  }

  .wrapper {
    display: grid;
    grid-template-columns: 1fr 0fr;
    overflow: hidden;

    height: 55vh;

    transition: grid-template-columns 0.3s ease-in-out;

    &.visible {
      grid-template-columns: 1fr 1fr;
    }
  }

  // Software
  .software-selection {
    flex: 1;
    overflow-y: auto;
    max-height: 100%;
  }

  .software-selection-card {
    align-items: center;
    display: flex;
    border-radius: 0.5rem;
    border-width: 1.5px;
    border-style: solid;
    box-sizing: border-box;

    padding: 0.75rem;

    transition: all 0.2s ease-in-out;

    &.active {
      border: 1.5px solid var(--sm-primary);
    }
  }

  .application-list {
    display: flex;
    max-height: 100%;
    overflow-y: auto;
    flex-direction: column;
    gap: 0.75rem;
    margin-top: 0.5rem;
    padding-right: 0.5rem;
  }

  .switch-wrapper {
    margin-left: auto;
    margin-bottom: auto;
    margin-top: auto;
    margin-right: 0.5rem;
  }

  .software-text {
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--sm-contrast);
  }

  .software-information {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;

    margin-left: 1rem;
  }

  .text-muted {
    color: var(--el-text-color-secondary);
    font-style: italic;
    gap: 0.5rem;
    display: flex;
    align-items: center;
  }
</style>
