import { addRxPlugin, MangoQuerySortPart } from 'rxdb'
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode'

import {
  Licence_Input,
  Licence_Output,
  LicenceUpdateIn,
  UserLicencesService,
} from '@/client'
import filterUtils from '@/common/util/filterUtils'
// Import schema.json
import schema from '@/stores/license.schema'

import { useFetch } from '@/common/useFetch'
import { until } from '@vueuse/shared'
import { db } from './_db'
import { filters, HasLoginFilter } from './license.filter'
import {
  _deleteLicense,
  _disableLicense,
  _enableLicense,
  _patchLicense,
  _postLicense,
} from './license.utils'

if (import.meta.env.MODE !== 'production') {
  addRxPlugin(RxDBDevModePlugin)
}

// Init DB
export const collectionInitiated = ref(false)

const currentLoadingAllLicenses = ref(false)

let allLicensesLoaded: Date | null = null

export async function initLicenseCollection() {
  // check if db is initialized
  if (!db.value) {
    return
  }

  // Check if collection is already existing in DB
  const collections = db.value?.collections
  if (collections.licenses) {
    collectionInitiated.value = true
    return
  }

  await db.value.addCollections({
    licenses: {
      schema: schema,
    },
  })

  collectionInitiated.value = true
}

//#region License Store
export async function getByID(id: string, ensureLoaded = true) {
  // TODO: This can be optimized and needs a refactor
  let _license = null
  try {
    _license = await db.value.licenses
      .findOne({
        selector: {
          _id: {
            $eq: id,
          },
        },
      })
      .exec()
  } catch (error) {
    // Do nothing
  }

  if (ensureLoaded && !_license) {
    await UserLicencesService.getLicenceApiV1SoftwareLicencesLicenceIdGet({
      licenceId: id,
    }).then((response) => {
      if (response) {
        _license = response
      }
    })
  }

  return _license
}

export async function addLicenses(licenses: Licence_Output[]) {
  if (!collectionInitiated.value) {
    await initLicenseCollection()
  }

  // Lowercase all emails
  // TODO: This should be done in the backend
  licenses.forEach((license) => {
    if (license.email) {
      license.email = license.email.toLowerCase()
    }
  })

  await db.value.licenses.bulkUpsert(licenses)
}

export async function getLicenses(
  filters?: LicenceFilterInstance[],
  {
    limit,
    skip,
    sort,
  }: {
    limit?: number
    skip?: number
    sort?: MangoQuerySortPart<Licence_Output>[]
  } = {}
) {
  // For now, we just take the first filter
  const mergedFilter = filterUtils.mergeFilters(filters || [])

  if (currentLoadingAllLicenses.value === true) {
    await until(currentLoadingAllLicenses).toBe(false)
  }

  if (allLicensesLoaded === null) {
    await reloadLicences()
  }

  const licenses = await db.value.licenses
    .find({ selector: mergedFilter, limit, skip, sort })
    .exec()

  return licenses
}

export async function getLicensesCount(filters?: LicenceFilterInstance[]) {
  // For now, we just take the first filter
  const mergedFilter = filterUtils.mergeFilters(filters || [])

  if (currentLoadingAllLicenses.value === true) {
    await until(currentLoadingAllLicenses).toBe(false)
  }

  if (allLicensesLoaded === null) {
    await reloadLicences()
  }

  const count = db.value.licenses.count({ selector: mergedFilter }).exec()
  return count
}

export async function getAnyLicenseHasLogin(filters?: LicenceFilterInstance[]) {
  const filtersCopy = filters ? [...filters] : []
  filtersCopy.push(new HasLoginFilter())

  // For now, we just take the first filter
  const mergedFilter = filterUtils.mergeFilters(filtersCopy || [])

  if (currentLoadingAllLicenses.value === true) {
    await until(currentLoadingAllLicenses).toBe(false)
  }

  if (allLicensesLoaded === null) {
    await reloadLicences()
  }

  const count = db.value.licenses.findOne({ selector: mergedFilter }).exec()
  return count
}

export async function reloadLicences(filter?: FilterArguments) {
  if (filter === undefined) {
    currentLoadingAllLicenses.value = true
  }

  //  Get software from backend
  const { data, error, loading } = await fetchLicenses(filter)
  //  Add software to DB
  if (data) {
    await addLicenses(toRaw(data))

    if (filter === undefined) {
      currentLoadingAllLicenses.value = false
    }
    allLicensesLoaded = new Date()
  }
  return { data, error, loading }
}

export async function getByAccountID(accountID: string) {
  const licenses = db.value.licenses
    .find({
      selector: {
        account_id: {
          $eq: accountID,
        },
      },
    })
    .exec()
  return licenses
}

// Parameters of  LicencesService.getLicencesApiV1SoftwareLicencesGet
type FilterArguments = Parameters<
  typeof UserLicencesService.getLicencesApiV1SoftwareLicencesGet
>[number]

// Backend API
export async function fetchLicenses(filter?: FilterArguments) {
  const _filter = filter || {}
  const { data, error, fetchData, loading } = useFetch(
    () => UserLicencesService.getLicencesApiV1SoftwareLicencesGet(_filter),
    null,
    { sendToast: true }
  )
  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}

// CRUD
async function deleteLicense(id: string) {
  return _deleteLicense(id).then((response) => {
    // Remove from DB
    db.value.licenses.findOne({ selector: { _id: id } }).remove()
    return response
  })
}

async function updateLicense(id: string, license: LicenceUpdateIn) {
  return await _patchLicense(id, license).then((response) => {
    // Update DB
    db.value.licenses.upsert({ _id: id, ...toRaw(response.data) })
    return response
  })
}

async function createLicense(license: Licence_Input) {
  return _postLicense(license).then((response) => {
    if (!response.data) return
    // Add to DB
    db.value.licenses.insert(toRaw(response.data))
    return response
  })
}

async function deactivateLicense(id: string) {
  return _disableLicense(id).then((response) => {
    // Update DB
    db.value.licenses.upsert({ _id: id, ...toRaw(response.data) })
    return response
  })
}

async function activateLicense(id: string) {
  return _enableLicense(id).then((response) => {
    // Update DB
    db.value.licenses.upsert({ _id: id, ...toRaw(response.data) })
    return response
  })
}

export type LicenceFilterType = (typeof filters)[keyof typeof filters]
export type LicenceFilterInstance = InstanceType<LicenceFilterType>
export type LicenceFilterNames = keyof typeof filters

export const licenseStore = {
  initLicenseCollection,
  getByID,
  addLicenses,
  getLicenses,
  getLicensesCount,
  getAnyLicenseHasLogin,
  reloadLicences,
  getByAccountID,
  fetchLicenses,
  get: getLicenses,

  filter: {
    ...filters,
  },

  utils: {
    ...filterUtils,
  },

  updateLicense,
  deleteLicense,
  createLicense,
  deactivateLicense,
  activateLicense,
}
