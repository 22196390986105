<!--
  !WARNING!
  This component was rushed and should be refactored.
 -->

<template>
  <!-- Edit Dialog  -->
  <LicenseModelEditDialog
    v-if="$props.licensesEditable && licenseToEdit"
    v-model:visible="licenseEditDialogVisible"
    v-model:softwareLicense="licenseToEdit"
    @saved="handleLicenseModelSaved"
    @add="emit('licenses-changed')" />

  <!-- Delete Dialog -->
  <DeleteConfirmDialog
    v-if="licenseToDelete"
    v-model:visible="deleteLicenseDialogVisible"
    :title="i18n.t('deleteLicense')"
    :sub-title="i18n.t('licenseModelDeleteConfirmText')"
    @cancel="deleteLicenseDialogVisible = false"
    @delete="handleDeleteLicenseModel" />

  <SmTable
    ref="softwareLicenseTable"
    :columns="softwareLicenseColumns"
    :data="filteredLicenseModelRows"
    :default-sorting="{
      by: 'name',
      asc: false,
    }"
    :selectable="selectable"
    key-field="_id"
    :select-disabled="state !== 'selection'"
    :hide-header="hideHeader"
    :loading="props.loading"
    :style="{ minHeight: props.loading ? '100px' : 'auto' }"
    @selected-all="emit('selectedAll', $event)"
    @unselected-all="emit('unselectedAll', $event)"
    @selected="emit('selected', $event)"
    @unselected="emit('unselected', $event)"
    @cell-click="handleCellClick">
    <!-- Auto License Model -->
    <template #automated="{ row }">
      <!-- Automated -->
      <SmTooltip v-if="row.external_id !== null" placement="left">
        <v-icon name="md-bolt-round" scale="1.2" class="fill-primary" />

        <template #content>
          <div style="max-width: 250px">
            <h3>{{ i18n.t('automatedLicenseModel') }}</h3>
            <p>{{ i18n.t('automatedLicenseModelTooltip') }}</p>
          </div>
        </template>
      </SmTooltip>

      <!-- Manual -->
      <SmTooltip v-else placement="left">
        <v-icon
          name="md-bolt-round"
          scale="1.2"
          fill=" var(--sm-contrast-muted)" />

        <template #content>
          <div style="max-width: 250px">
            <h3>{{ i18n.t('manualLicenseModel') }}</h3>
            <p>{{ i18n.t('manualLicenseModelTooltip') }}</p>
          </div>
        </template>
      </SmTooltip>
    </template>

    <!-- Name -->
    <template #name="{ row }">
      <div
        style="display: flex; flex-direction: column; gap: 0.2rem"
        :class="{
          'license-model-deleted': licensesToDelete.includes(row._id),
          'license-model-added': licensesToAdd.includes(row._id),
          'license-model': true,
        }">
        {{ row.name }}
        <span v-if="row.subtitle" class="no-data">
          {{ row.subtitle }}
        </span>
      </div>
    </template>

    <!-- Quota Information -->
    <template #quota_information="{ row }">
      <QuotaProgressBar
        v-if="applicationDetailsStore.quota[row._id] || row.quota_information"
        :quota_information="
          applicationDetailsStore.quota[row._id] || row.quota_information
        " />

      <span v-else class="empty-quota-placeholder"></span>
    </template>

    <!-- Menu -->
    <template #menu="{ row }">
      <SmDropdown trigger="click" size="small">
        <SmDropdownItem
          icon="md-modeedit-round"
          @click="handleEditLicenseModelClick(row)">
          {{ i18n.t('edit') }}
        </SmDropdownItem>
        <SmDropdownItem
          hover-color="var(--sm-magenta)"
          icon="md-delete-round"
          @click="handleDeleteLicenseModelClick(row)">
          {{ i18n.t('delete') }}
        </SmDropdownItem>
      </SmDropdown>
    </template>
  </SmTable>

  <!-- Add license -->
  <!-- Plus left and text -->
  <div
    v-if="$props.showAddRow && !props.loading"
    class="add-row"
    @click="openAddLicense">
    <v-icon name="md-add-round" scale="1.1" />
    <span>{{ i18n.t('addLicense') }}</span>
  </div>
</template>

<script setup lang="ts">
  import { ComponentExposed } from 'vue-component-type-helpers'
  import { useI18n } from 'vue-i18n'

  import {
    Account,
    ApiError,
    LicenceOut,
    LicenceStatus,
    SoftwareLicense,
    SoftwareLicenseMini,
    SoftwareOut,
  } from '@/client'
  import { deleteSoftwareLicense } from '@/common/license'

  import { licenseStore } from '@/stores/licenseV2.store'
  import { useRoute } from 'vue-router'
  import DeleteConfirmDialog from './DeleteConfirmDialog.vue'
  import { Column } from './sm/SmTable.types'
  import SmTable from './sm/SmTable.vue'
  import { useApplicationDetailsStore } from '@/views/ApplicationsDetail/ApplicationDetailsView.store'
  import { nanoid } from 'nanoid'

  interface i_result {
    status: 'success' | 'error'
    message: string
    response?: LicenceOut
  }

  const i18n = useI18n()
  const applicationDetailsStore = useApplicationDetailsStore()

  const licenseToEdit = ref<SoftwareLicense | null>()
  const licenseToDelete = ref<SoftwareLicense | null>(null)

  const licenseEditDialogVisible = ref(false)
  const deleteLicenseDialogVisible = ref(false)

  const softwareLicenseTable =
    ref<ComponentExposed<typeof SmTable<SoftwareLicense>>>()
  const state: Ref<'selection' | 'loading' | 'done'> = ref('selection')
  const result = ref<i_result | null>(null)

  const route = useRoute()

  const isSSO = computed(() => route.query.sso === 'true')
  const currentSubaccount = computed(() => route.params.subAccountId as string)

  // Emits
  const emit = defineEmits<{
    'user-added': []
    'update:state': [value: 'selection' | 'loading' | 'done']
    'licenses-changed': []
    'software-changed': []
    selected: [value: SoftwareLicense]
    unselected: [value: SoftwareLicense]
    selectedAll: [value: SoftwareLicense[]]
    unselectedAll: [value: SoftwareLicense[]]
  }>()

  // Props
  export type Props = {
    software: SoftwareOut
    softwareLicenses: SoftwareLicense[]
    license?: LicenceOut // Needed for edit mode
    user?: Account[] // Needed for add mode
    filter?: '' | 'ActiveAndEdit'
    subAccountId?: string | null // null is assignable to all subaccounts
    selectable?: boolean
    loading?: boolean
    showAddRow?: boolean
    hideHeader?: boolean
    licensesEditable?: boolean
    filterFlatrate?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    filter: '',
    selectable: true,
    loading: false,
    showAddRow: false,
    hideHeader: false,
    licensesEditable: false,
    filterFlatrate: false,
    subAccountId: null,
    license: undefined, // Needed for edit mode
    user: undefined, // Needed for add mode
  })

  // Table columns
  const softwareLicenseColumns = ref<Column<SoftwareLicense>[]>([
    {
      label: '',
      key: 'automated',
      width: 'auto',
      sortable: false,
    },
    {
      label: 'Lizenz',
      key: 'name',
      width: 1,
      sortFn: (a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    },
  ])

  const selection = computed(() => {
    if (!softwareLicenseTable.value) return []
    return softwareLicenseTable.value.selection as SoftwareLicense[]
  })

  // Table rows
  // Here we add the id to the license model (seems to be missing in the api)

  // Get the license models that are not already in the license
  const licensesToAdd = computed(() => {
    if (!props.license) return [] // Guard clause if no license is given (create new license)
    if (!props.license.software_licenses) return []
    const softwareLicenseKeys = Object.keys(props.license.software_licenses)
    if (!softwareLicenseTable.value) return []
    const selection: SoftwareLicense[] = softwareLicenseTable.value?.selection
    if (!selection) return []
    const selectionIds = selection.map((model) => model._id)
    const toAdd = selectionIds.filter((id) => !softwareLicenseKeys.includes(id))
    return toAdd
  })

  const licensesToDelete = computed(() => {
    if (!props.license) return [] // Guard clause if no license is given (create new license)
    if (!props.license.software_licenses) return []
    const softwareLicenseKeys = Object.keys(props.license.software_licenses)
    const selection = softwareLicenseTable.value?.selection
    if (!selection) return []
    const selectionIds = selection.map((model: SoftwareLicense) => model._id)
    const toDelete = softwareLicenseKeys.filter(
      (id) => !selectionIds.includes(id)
    )
    return toDelete
  })

  const filteredLicenseModelRows = computed(() => {
    if (isSSO.value) {
      return props.softwareLicenses.filter(
        (license) => license.sub_account_id == currentSubaccount.value || null
      )
    }

    // If edit mode, only return not_editable_at_the_user = false
    if (props.selectable) {
      return props.softwareLicenses.filter(
        (license) => license.not_editable_at_the_user === false
      )
    }
    return props.softwareLicenses
  })

  // Check if any license model has a quota
  const hasQuota = computed(() => {
    return applicationDetailsStore.quota
  })

  const editMode = computed(() => {
    return props.license !== null
  })

  function execute() {
    switch (state.value) {
      case 'selection': // Add the license
        if (editMode.value) {
          updateLicense()
        } else {
          addLicense()
        }
        break
      case 'loading': // Do nothing
        break
    }
  }

  function softwareLicenseSelectionToSoftwareLicenseMiniRecord() {
    const softwareLicenses: Record<string, SoftwareLicenseMini | null> = {}
    selection.value.forEach((softwareLicense) => {
      softwareLicenses[softwareLicense._id] = {
        name: softwareLicense.name,
      }
    })
    licensesToDelete.value.forEach((softwareLicenseId) => {
      softwareLicenses[softwareLicenseId] = null
    })
    return softwareLicenses
  }

  // Send request to add license
  function addLicense() {
    if (!props.user) return
    state.value = 'loading'
    const software_licenses =
      softwareLicenseSelectionToSoftwareLicenseMiniRecord()

    for (const _single_user of props.user) {
      licenseStore
        .createLicense({
          status: LicenceStatus.ACTIVE,
          email: _single_user?.email,
          name: _single_user?.name,
          software_id: props.software._id,
          software_licenses: software_licenses as Record<
            string,
            SoftwareLicenseMini
          >,
          sub_account_id: props.subAccountId!,
        })
        .then((response) => {
          if (!response) return
          if (!response.error) {
            result.value = {
              status: 'success',
              message: 'Nutzer erfolgreich hinzugefügt',
              response: response.data || undefined,
            }
            emit('user-added')
            emit('licenses-changed')
          } else {
            if (response.error instanceof ApiError)
              result.value = {
                status: 'error',
                message: response.error.body.detail.message,
              }
            else
              result.value = {
                status: 'error',
                message: i18n.t('error.general.unknown_error.message'),
              }
          }

          applicationDetailsStore.updateQuota(response.data?.software_licenses)
        })
        .finally(() => {
          state.value = 'done'
        })
    }
  }

  function updateLicense() {
    state.value = 'loading'
    if (!props.user) return
    if (!props.license?._id) return

    const requestValue = {
      // Ignore linting next line, because the license is not null
      software_licenses:
        softwareLicenseSelectionToSoftwareLicenseMiniRecord() as Record<
          string,
          SoftwareLicenseMini
        >,
    }

    licenseStore
      .updateLicense(props.license._id, requestValue)
      .then((response) => {
        if (!response.error) {
          result.value = {
            status: 'success',
            message: 'Lizenzen erfolgreich gespeichert',
            response: response.data || undefined,
          }
          emit('user-added')
          emit('licenses-changed')
        } else {
          if (response.error instanceof ApiError) {
            result.value = {
              status: 'error',
              message: response.error.body.detail.message,
            }
          } else {
            result.value = {
              status: 'error',
              message: i18n.t('error.general.unknown_error.message'),
            }
          }
        }
        applicationDetailsStore.updateQuota(
          requestValue.software_licenses,
          props.license?.software_licenses
        )
      })
      .finally(() => {
        state.value = 'done'
      })
  }

  function deselectLicenseModel(softwareLicense: string) {
    softwareLicenseTable.value?.deselectById(softwareLicense)
  }

  function handleCellClick(
    _: unknown,
    columnKey: string,
    row: SoftwareLicense
  ) {
    if (columnKey === 'menu') return

    if (props.licensesEditable) {
      licenseEditDialogVisible.value = true
      licenseToEdit.value = row
    }

    // select row if not already selected
    if (
      !softwareLicenseTable.value?.selection.map((l) => l._id).includes(row._id)
    ) {
      emit('selected', row)
      softwareLicenseTable.value?.selectById(row._id)
    } else {
      softwareLicenseTable.value?.deselectById(row._id)
      emit('unselected', row)
    }
  }

  function handleEditLicenseModelClick(row: SoftwareLicense) {
    licenseEditDialogVisible.value = true
    licenseToEdit.value = row
  }

  async function handleLicenseModelSaved() {
    // Refetch software
    emit('software-changed')
    emit('licenses-changed')

    // Close dialog
    licenseEditDialogVisible.value = false
  }

  async function handleDeleteLicenseModel() {
    if (!licenseToDelete.value) return
    await deleteSoftwareLicense(licenseToDelete.value._id)
    emit('licenses-changed')
    deleteLicenseDialogVisible.value = false
  }

  function handleDeleteLicenseModelClick(row: SoftwareLicense) {
    deleteLicenseDialogVisible.value = true
    licenseToDelete.value = row
  }

  // Add quota column if any license model has a quota
  watch(
    hasQuota,
    (value) => {
      if (value) {
        // Check if quota column is already added
        if (
          softwareLicenseColumns.value.some(
            (column) => column.key === 'quota_information'
          )
        )
          return

        const menuIndex = softwareLicenseColumns.value.findIndex(
          (column) => column.key === 'menu'
        )

        if (menuIndex > 0) {
          softwareLicenseColumns.value.splice(menuIndex, 0, {
            label: 'Quota',
            key: 'quota_information',
            width: '130px',
          })
        } else {
          softwareLicenseColumns.value.push({
            label: 'Quota',
            key: 'quota_information',
            width: '130px',
          })
        }
      }
    },
    { immediate: true }
  )

  // Select all license models that are already in the license
  onMounted(() => {
    if (!props.license?.software_licenses) return
    const softwareLicenseKeys = Object.keys(props.license.software_licenses)
    softwareLicenseTable.value?.selectByIds(softwareLicenseKeys)
  })

  watch(
    () => props.softwareLicenses,
    () => {
      if (Object.keys(applicationDetailsStore.$state.quota).length) return
      applicationDetailsStore.loadAllQuotas(props.softwareLicenses)
    }
  )

  // Watch for changes in props.license
  watch(
    () => props.license,
    () => {
      if (!props.license?.software_licenses) return
      softwareLicenseTable.value?.clearSelection()
      const softwareLicenseKeys = Object.keys(props.license.software_licenses)
      softwareLicenseTable.value?.selectByIds(softwareLicenseKeys)
    }
  )

  // When licenseEditDialogVisible is set to false, reset licenseToEdit
  watch(
    licenseEditDialogVisible,
    (value) => {
      if (!value) {
        licenseToEdit.value = null
      }
    },
    { immediate: true }
  )

  watch(
    () => props.licensesEditable,
    (value) => {
      if (value) {
        // Add menu column
        softwareLicenseColumns.value.push({
          label: '',
          key: 'menu',
          width: '30px',
        })
      }
    },
    { immediate: true }
  )

  defineExpose({
    execute: execute,
    state: state,
    result: result,
    selection: selection,
    deselectLicenseModel: deselectLicenseModel,
    filteredLicenseModelRows: filteredLicenseModelRows,
    table: softwareLicenseTable,
  })

  async function openAddLicense() {
    const tempId: string = 'new-' + nanoid()

    const newForm = {
      _id: tempId,
      sub_account_id: null,
    } as SoftwareLicense

    // Check if SSO
    // If SSO --> The license has to be bound to the subaccount or it will be hidden on reload
    if (isSSO) {
      newForm.sub_account_id = currentSubaccount.value
    }

    licenseEditDialogVisible.value = true

    licenseToEdit.value = newForm
  }
</script>

<style lang="scss">
  .license-model {
    transition: all 0.2s ease-in-out;
  }

  .license-model-deleted {
    font-weight: 600;
    color: var(--sm-magenta);
  }

  .license-model-added {
    font-weight: 600;
    color: var(--sm-primary);
  }
</style>

<style lang="scss" scoped>
  .add-row {
    border-top: 1px solid var(--el-border-color-lighter);

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;

    padding: 1rem 0;
    font-size: 1.1rem;

    cursor: pointer;
  }
</style>
