/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BackgroundJobSoftwareUserAddExecutionData } from './BackgroundJobSoftwareUserAddExecutionData';
import type { BackgroundJobStatus } from './BackgroundJobStatus';
export type BackgroundJobExecutionBase_Literal_software_user_add__BackgroundJobSoftwareUserAddExecutionData_ = {
    type: BackgroundJobExecutionBase_Literal_software_user_add__BackgroundJobSoftwareUserAddExecutionData_.type;
    execution_id: string;
    executed_at: string;
    data?: (BackgroundJobSoftwareUserAddExecutionData | null);
    status?: BackgroundJobStatus;
    error_event_id?: (string | null);
    background_job_ids_enqueued?: Array<string>;
};
export namespace BackgroundJobExecutionBase_Literal_software_user_add__BackgroundJobSoftwareUserAddExecutionData_ {
    export enum type {
        SOFTWARE_USER_ADD = 'software_user_add',
    }
}

